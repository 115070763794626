body {
  background-color: #404040 !important;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.bar_image {
  margin-top: 10px;
  width: 100%;
  height: 0.625rem;
  background-image: url(../assets/Calibrite-colour-strip.svg);
  background-size: 18.125rem;
}

.bar_image.small {
  margin-top: 0px;
}

hr.dashed {
  height: 1px;
  color: #e0e0e1;
  /* border-top: 0.063rem white; */
  padding-bottom: 0.938rem;
}

.form-error {
  color: tomato;
}

option {
  color: black;
}

.circle_lang {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  line-height: 2.5rem;
  text-align: center;
  font-weight: bold;
  background-color: black;
  color: white;
  margin-bottom: 10px;
}

/* .disabled-input:disabled {
  color: white;
  opacity: 1;
} */

@keyframes fade {
  from {
    opacity: 0.8;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.8;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 2000ms infinite;
  -webkit-animation: fade 2000ms infinite;
}
